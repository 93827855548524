import React from 'react';
import styled from "styled-components";
import {VArrowSvg, WarningSvg2, XArrowSvg} from "../../../svg";
import {BadaraStyled, ImagesStyled} from "./CommonUploadImages";
import CONSTS from "../../../consts";

const WrapperStyled = styled.div`
    background: rgba(255, 198, 112, 0.09);
    border-radius: 12px;
    display: grid;
`;

const TitleStyled = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    padding: 14px 14px 0 0;

    @media (max-width: 460px) {
        text-align: center;
        padding: 8px 0 0 0;
        justify-self: center;
        align-items: center;
    }

    h3 {
        font-size: 38px;
        font-weight: 700;
        color: rgba(255, 196, 61, 1);
        margin: 0;
        text-align-last: start;
        margin-inline-start: 12px;

        @media (max-width: 460px) {
            font-size: 28px;
        }
    }
    
    svg{
        @media (max-width: 460px) {
            width: 30px;
            height: 30px;
        }
    }
`;

const ContentListStyled = styled.div`
    text-align-last: start;
    margin-inline-start: 24px;
    margin-top: 14px;

    @media (max-width: 460px) {
        margin-inline-start: 0;
        margin-top: 4px;
        text-align-last: center;
    }

    p {
        margin: 0;
        font-weight: 400;
        font-size: 19px;
        line-height: 1.8;
        text-align: right;

        @media (max-width: 460px) {
            font-size: 16px;
            line-height: 1.3;
            text-align: center;
        }
    }
`;

const ImagesListStyled = styled.div`
    display: grid;
    grid-template-columns: 109px 109px 109px 109px;
    grid-gap: 25px;
    margin-inline-start: 25px;

    @media (max-width: 460px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        margin-inline-start: 0;
        
        img{
            width: 100%;
        }
    }
`;

const UploadImagesDescription = ({langData}) => {
    const imagesToShow = [6003, 6002, 6006, "normal"];

    return (
        <WrapperStyled>
            <TitleStyled>
                <WarningSvg2/>
                <h3>{langData.stage1.several_things}</h3>
            </TitleStyled>
            <ContentListStyled>
                <p>{langData.stage1.several_things_cont.first}</p>
            </ContentListStyled>

            <ImagesListStyled>
                {imagesToShow.map(imageCode => {
                    const isNormal = imageCode === 'normal';
                    const errorLabel = langData.image_error_labels[imageCode];
                    const errorImage = CONSTS.error_images_links[imageCode];

                    let classes = "";
                    if (isNormal) {
                        classes += 'norm';
                    }

                    return (
                        <ImagesStyled key={imageCode} className="many">
                            <div>
                                <BadaraStyled className={classes}>
                                    {!isNormal && (
                                        <XArrowSvg/>
                                    )}
                                    {isNormal && (
                                        <VArrowSvg/>
                                    )}
                                </BadaraStyled>
                                <img src={errorImage} alt=""/>
                                <p>{errorLabel}</p>
                            </div>
                        </ImagesStyled>
                    )
                })}
            </ImagesListStyled>
        </WrapperStyled>
    );
};

export default UploadImagesDescription;